import React from "react";
import "./Project.css"

function Project({name, img, duration, takeaway, site})
{

    return(
        <div className="mb-4 col-12 project-container">
            <img className="mb-4 col-12 col-md-4 project-img" src={require(`../../Images/Projects/${img}`)} alt={name} />
            <div className="col-0 offset-md-1"></div>
            
            <div className="p-2 pt-0 col-12 col-md-6 project-content">
                <h3>{name}</h3>
                <p>Duration: {duration}</p>
                <p>Takeaway: {takeaway}</p>

            <div className="links">
                <a className="btn btn-dark" href={site} target="_blank" rel="noreferrer">Project</a>
            </div>
            </div>
        </div>
    );
}

export default Project;