import React, { useState, useRef } from "react";
import "./HomePage.css";

import cs from "../Images/Icons/c-sharp.png";
import cpp from "../Images/Icons/cpp.png";
import git from "../Images/Icons/git.png";
import node from "../Images/Icons/node.png";
import rect from "../Images/Icons/react.png";
import sql from "../Images/Icons/sql.png";
import ue from "../Images/Icons/ue.png";
import unity from "../Images/Icons/unity.png";
import github from "../Images/Icons/github.png";
import linkedin from "../Images/Icons/linkedin.png";
import resume from "../Images/Icons/resume.png";
import resumeFile from "../JoaquinRamirez_Resume.pdf"

import Project from "../Components/Project/Project";
import projectData from "../Projects.json";
import emailjs from "@emailjs/browser";


function HomePage()
{
    const [client_name, setName] = useState("");
    const [client_email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const form = useRef();

    function handleReset()
    {
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
    }

    function handleSubmit(e)
    {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
            publicKey: process.env.REACT_APP_PUBLIC_ID
        })
        .then(
            () => 
            {
                alert("Email sent successfully!");
                handleReset();
            },
            (err) => 
            {
                alert(err.text)
            }
        )
    }

    return(
        <div>
            <div id='intro'>
                <div className='shader'>
                    <div className="col-0 offset-md-1"></div>
                    <div className="p-2 col-md-5 intro-content">
                        <h1>Joaquin Ramirez</h1>
                        <h3>Software Developer that specializes in Web Design and Game Development</h3>
                        <div className="constainer col-12 intro-links">
                            <a className="p-2 col-2" rel="noreferrer" href="https://github.com/Tavera15" target="_blank"><img className="col-12" src={github} alt="github"/></a>
                            <a className="p-2 col-2" rel="noreferrer" href="https://www.linkedin.com/in/joaquin-ramirez-5041901a2/" target="_blank"><img className="col-12" src={linkedin} alt="linkedin"/></a>
                            <a className="p-2 col-2" rel="nonreferrer" href={resumeFile} target={resume} download><img className="col-12" src={resume} alt="linkedin"/></a>
                        </div>
                    </div>
                </div>
            </div>
                


            <div id='skills' className="container p-4 mb-4">
                <img className="col-1" src={cs} alt="cs"/>
                <img className="col-1" src={cpp} alt="cpp"/>
                <img className="col-1" src={rect} alt="react"/>
                <img className="col-1" src={node} alt="node"/>
                <img className="col-1" src={sql} alt="sql"/>
                <img className="col-1" src={git} alt="git"/>
                <img className="col-1" src={unity} alt="unity"/>
                <img className="col-1" src={ue} alt="ue"/>
            </div>


            <div id='work' className="container">
                <hr/>
                
                {
                    projectData.map((p, i) => {
                        return <Project p={p} name={p.name} img={p.img} duration={p.duration} takeaway={p.takeaway} site={p.site} key={i} />
                    })
                }

            </div>
            
            <div id='contact' className="bg-dark">
                <div className="container contact-form p-4">
                    <h1>Contact</h1>

                    <form ref={form} className="col-md-6" onSubmit={e => handleSubmit(e)} onReset={handleReset}>
                        <div className="row">

                            <div className="mb-3 col-md-6">
                                <input type="text" className="form-control" id="validationCustom01" name="client_name" value={client_name} onChange={e => setName(e.target.value)} placeholder="Name" required />
                            </div>

                            <div className="mb-3 col-md-6">
                                <input type="email" className="form-control" id="validationCustom02" name="client_email" value={client_email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
                            </div>
                        </div>

                        <div className="mb-3">
                            <input type="text" className="form-control" id="validationCustom03" name="subject" value={subject} onChange={e => setSubject(e.target.value)} placeholder="Subject" required />
                        </div>

                        <div className="mb-3">
                            <textarea className="form-control" rows={4} name="message" value={message} onChange={e => setMessage(e.target.value)} placeholder="Message"></textarea>
                        </div>

                        <div className="col contact-btns">
                            <button className="btn btn-success col-5" type="submit">Send</button>
                            <button className="btn btn-outline-light col-5" type="reset">Clear</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default HomePage;